import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
// import { useSpring, animated } from "react-spring";
// import { useInView } from "react-intersection-observer";
import "./hero.css";

import part1 from "../../img/C1-1.png";
import part2 from "../../img/car02.png";
import part3 from "../../img/big 1-4.png";
import summit from "../../img/Summit.png";

function Hero({ openModal }) {
  // const [ref, inView] = useInView({ threshold: 0.01 });

  // const props = useSpring({
  //   to: {
  //     opacity: inView ? 1 : 0,
  //     transform: inView ? "translateX(0)" : "translateX(-20%)",
  //   },
  //   config: { duration: 1000 },
  // });

  return (
    <div id="Home" className="main-donation-d2">
      <div className="achievementsMain-d2" id="Feats">
        <Swiper
          modules={[Navigation, Autoplay, Pagination]}
          autoplay={{ delay: 4000 }}
          slidesPerView={1}
          pagination={{ clickable: true }}
          loop={true}
          className="carousal-container"
        >
          <SwiperSlide>
            <div className="hero-content">
              <div className="achievementLeft-d2">
                <div className="statusContainer2-d2">
                  {/* <div className="summit">
                    <img src={summit} alt="summit" />
                  </div> */}
                  <div className="statusTexts-d2">
                    <div className="header-wrap">
                      <h2 className="statusHeader-d2">
                        Simplifying Contract Labor​ Management
                      </h2>
                    </div>
                    <p className="statusPara-d2">
                      Helping you with everything needed to manage your contract
                      labor – Verified digital onboarding, Real time background
                      checks, Compliance management with proactive alerts and
                      notifications and more.
                    </p>
                  </div>
                  <div className="button-row">
                    <button onClick={openModal} className="award-button-h">
                      Book Demo
                    </button>
                  </div>
                </div>
              </div>
              <div className="world-map">
                <img src={part1} alt="World map" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-content">
              <div className="achievementLeft-d2">
                <div className="statusContainer2-d2">
                  {/* <div className="summit">
                    <img src={summit} alt="summit" />
                  </div> */}
                  <div className="statusTexts-d2">
                    <div className="header-wrap">
                      <h2 className="statusHeader-d2">
                        Celebrating our partnership with UKG
                      </h2>
                    </div>
                    <p className="statusPara-d2">
                      We are honored to be recognized as a trusted
                      Implementation Partner, Technology Partner, and Innovative
                      Partner by UKG. These badges reflect our commitment to
                      delivering cutting-edge solutions and exceptional services
                      in workforce management.
                    </p>
                  </div>
                  <div className="button-row">
                    <button onClick={openModal} className="award-button-h">
                      Book Demo
                    </button>
                    <button
                      onClick={() => (window.location.href = "/services")}
                      className="outlined-button"
                    >
                      Know more
                    </button>
                  </div>
                </div>
              </div>
              <div className="world-map">
                <img src={part3} alt="World map" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="hero-content">
              <div className="achievementLeft-d2">
                <div className="statusContainer2-d2">
                  {/* <div className="summit">
                    <img src={summit} alt="summit" />
                  </div> */}
                  <div className="statusTexts-d2">
                    <div className="header-wrap">
                      <h2 className="statusHeader-d2">
                        Expertise in Implementing UKG offerings
                      </h2>
                    </div>
                    <p className="statusPara-d2">
                      We specialize in delivering tailored workforce management
                      solutions that drive efficiency and compliance. From
                      custom implementations and advanced reporting to ongoing
                      support, our expert team ensures seamless operations and
                      sustainable growth for your business.
                    </p>
                  </div>
                  <div className="button-row">
                    <button onClick={openModal} className="award-button-h">
                      Book Demo
                    </button>
                    <button
                      onClick={() => (window.location.href = "/services")}
                      className="outlined-button"
                    >
                      Know more
                    </button>
                  </div>
                </div>
              </div>
              <div className="world-map">
                <img src={part2} alt="World map" />
              </div>
            </div>
          </SwiperSlide>
          {/* Add more SwiperSlides here if needed */}
        </Swiper>
      </div>
    </div>
  );
}

export default Hero;
