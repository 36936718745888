import "./hcm.css";
import HcmData from "./hcmData";
import Contact_us from "../../contact_us/contact_us";
import Services from "../../services/services";
import Topbar from "../../topbar/topbar";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import img1 from "../../../img/hcm1.jpg";

export default function ServicesPage({openModal}) {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div id="Services">
      {/* <TopBar /> */}

      <Topbar />
      <Services openModal={openModal} />

      <Contact_us />
      <Footer />
    </div>
  );
}
