import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../Footer/Footer";
import "./privacyPolicy.css";
import img1 from "../../../img/compliances.jpg";

export default function PrivacyPolicy() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };

  return (
    <div>
      <div className="mainContPriv">
        <div className="segregateContainer-p">
          <div className="leftContent-p">
            <h1 ref={solutionsAndServicesRef}>PRIVACY POLICY</h1>
            <p>
              WIKIWORKS TECHNOLOGIES PRIVATE LIMITED (“VYN”, “WikiWorks”, we, us and
              terms of similar meaning) owns and operates the sites www.wikiworks.in
              and www.vyn.wikiworks.in
            </p>
            
            <h2>1. TYPES AND USES OF INFORMATION</h2>
            <p>
              a. WikiWorks gathers personal information from site visitors and through
              their VYN application from time to time in order to enhance existing
              offerings or provide additional services. This may include contact
              information such as name, email address, telephone number, and company
              name.
            </p>
            <p>
              b. WikiWorks also may monitor customer traffic patterns and site usage to
              help refine and improve the design and layout of the website as well as the
              user experience.
            </p>
            <p>
              c. WikiWorks does not make personal information available to third parties
              who trade or rent information for direct marketing purposes.
            </p>

            <h2>2. LINKS</h2>
            <p>
              Our website may contain links to other websites, and those third-party websites
              may collect personal information about you. We are not responsible for the
              privacy practices of other businesses or the content of websites linked to our site.
            </p>

            <h2>3. SECURITY OF INFORMATION</h2>
            <p>
              a) WikiWorks endeavors to maintain appropriate safeguards and adequate
              physical, procedural, and technical security with respect to our offices and
              information storage facilities.
            </p>
            <p>
              b) To ensure the integrity and privacy of personal information, WikiWorks
              undertakes necessary audits and encryption for secure access and data transmission.
            </p>

            <h2>4. NOTIFICATION OF PRIVACY POLICY CHANGES/CHOICE</h2>
            <p>
              WikiWorks reserves the right to change, modify, add, or remove portions of this
              Policy at any time. All changes will be posted on this page.
            </p>

            <h2>5. LEGAL DISCLAIMER</h2>
            <p>
              WikiWorks may disclose Personal Information when required by law or to protect
              the rights, property, or personal safety of WikiWorks, its users, and the public.
            </p>

            <h2>6. CONTACTING WIKIWORKS</h2>
            <p>
              If you have any questions about this privacy policy, please contact us at:
              sales@wikiworks.in
            </p>
          </div>
          {/* <div className="imageRight">
            <img src={img1} alt="Compliance" />
          </div> */}
        </div>

        <button
          onClick={handleGoBack}
          className="learnButton"
        >
          I have read the privacy policy
        </button>
      </div>
      <Footer />
    </div>
  );
}
