import React from "react";
import "./services.css";
import pr2 from "../../img/Designer (8) 1.png";
import coreImg from "../../img/serv_15.png";
import pr3 from "../../img/Feature 2.png";
import pr4 from "../../img/Feature 3.png";
// import pr4 from "../../img/wikiworks logo 230725-01 2.png";

import { Swiper, SwiperSlide } from "swiper/react";
import CertifiedPartner from "../certified_partner/certified_partner"
import ServiceOfferings from "../service_offering/service_offerings"
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Services({openModal}) {
  return (
    <div className="whoWeAreMain-o-j">
      {/* <div className="who-we-are-main-head-o">Features</div> */}
      <div className="core-value-cont-j">
        <div className="core-value-up-j">
          <img className="core-img-j" src={coreImg} alt="" />
        </div>
        
      </div>
      <CertifiedPartner/>
      <ServiceOfferings openModal={openModal}/>

      
    </div>
  );
}

export default Services;
