import React, { useState } from "react";
import "./service_offering.css";
import pr2 from "../../img/serv_13.png";
import pr3 from "../../img/serv_14.png";
import pr4 from "../../img/serv_5.png";
import pr5 from "../../img/serv_3.png";
import pr6 from "../../img/card01.png";
import pr7 from "../../img/serv_2.png";


const ServiceOfferings = ({openModal}) => {
  const [isHovered1, setIsHovered1] = useState(true);

  const handleHover1 = () => {
    setIsHovered1(!isHovered1);
  };

  const [isHovered2, setIsHovered2] = useState(true);

  const handleHover2 = () => {
    setIsHovered2(!isHovered2);
  };
  const [isHovered3, setIsHovered3] = useState(true);

  const handleHover3 = () => {
    setIsHovered3(!isHovered3);
  };

  const [isHovered4, setIsHovered4] = useState(true);

  const handleHover4 = () => {
    setIsHovered4(!isHovered4);
  };
  const [isHovered5, setIsHovered5] = useState(true);

  const handleHover5 = () => {
    setIsHovered5(!isHovered5);
  };

  return (
    <div id="Solutions" className="mainContainer-k">
      <div className="mainHeader-k">Why we're your Perfect Match</div>
      <div className="firstRow-k">
        <img src={pr6} alt="" />
        <img src={pr2} alt="" />

        <img src={pr4} alt="" />
      </div>
      <div className="firstRow-k">

        <img src={pr5} alt="" />
        <img src={pr3} alt="" />
        <img src={pr7} alt="" />
      </div>
     
      <div className="mainHeader-d-k">You are one step closer to Efficiency!</div>

      <div className="but-centre-k">
          <button onClick={openModal} className="award-button-b-k">Talk to our sales team</button>
      </div>
    </div>
  );
};

export default ServiceOfferings;
