import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer-main">
      {/* <div className="topRow">
                <div>
                    <h1>Xenoooz</h1>
                    <p>Choose your favourite destination</p>
                </div>
                <div className='iconCont'>
                    <a href="/">
                        <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a href="/">
                        <i className="fa-brands fa-square-twitter"></i>
                    </a>
                    <a href="/">
                        <i className="fa-brands fa-pinterest"></i>
                    </a>
                    <a href="/">
                        <i className="fa-brands fa-square-instagram"></i>
                    </a>
                </div>

            </div> */}
      <div className="bottomRow">
        <div>
          <h4>Quick links</h4>
          <a href="/aboutus">About us</a>
          <a href="/services">Services</a>
          <a href="/">Blogs</a>
          <a href="/">FAQs</a>
          <a href="/">careers</a>
          <a href="/privacypolicy">Privacy Policy</a>
          <a href="/">Refund Policy</a>
          <a href="/">Terms and Conditions</a>
        </div>
        <div>
          <h4>Get connected</h4>
          {/* <a href="/">Github</a>
                    <a href="/">issues</a>
                    <a href="/">project</a>
                    <a href="/">twitter</a> */}

          <div className="icons-main">
            <a href="/">
              <i className="fa-brands fa-facebook"></i>
            </a>
            {/* <a href="/">
                <i className="fa-brands fa-square-twitter"></i>
              </a> */}

            <a href="/">
              <i className="fa-brands fa-square-instagram"></i>
            </a>
            <a target="_blank" href="https://in.linkedin.com/company/wikiworks-technologies">
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
        <div>
          <h4>Get in touch</h4>
          <a className="small-content" href="">
            WikiWorks Technologies Private Limited. 4th Floor, #307, 5th A
            Cross, HRBR Layout, Bengaluru - 560043​
          </a>
          <a className="small-content" href="">
            Email: sales@wikiworks.in
          </a>
          <a className="small-content" href="">
            Call Us: +91-9560949988
          </a>
        </div>
        <div>
          <h4>Newsletter</h4>
            <form action="">
              <input placeholder="email@newsletter.com" className="footer-input" type="text" />
            </form>
          {/* <a href="/">privacy policy</a> */}
          <a href="">
            <button className="award-button-z">Subscribe</button>
          </a>{" "}
        </div>
      </div>
    </div>
  );
}
