import React, { useState } from "react";
import "./solutions_and_services.css";
import pr2 from "../../img/Group 127.png";
import pr3 from "../../img/Group 151.png";
import pr4 from "../../img/Group 119 (1).png";
import pr5 from "../../img/Group 152.png";
import pr6 from "../../img/Group 150.png";
import pr7 from "../../img/Glass 3.png";
import pr8 from "../../img/Group 2364.png";
import pr9 from "../../img/Group 2373.png";
import pr10 from "../../img/Group 124.png";

const Solutions_and_services = ({openModal}) => {
  const [isHovered1, setIsHovered1] = useState(true);

  const handleHover1 = () => {
    setIsHovered1(!isHovered1);
  };

  const [isHovered2, setIsHovered2] = useState(true);

  const handleHover2 = () => {
    setIsHovered2(!isHovered2);
  };
  const [isHovered3, setIsHovered3] = useState(true);

  const handleHover3 = () => {
    setIsHovered3(!isHovered3);
  };

  const [isHovered4, setIsHovered4] = useState(true);

  const handleHover4 = () => {
    setIsHovered4(!isHovered4);
  };
  const [isHovered5, setIsHovered5] = useState(true);

  const handleHover5 = () => {
    setIsHovered5(!isHovered5);
  };

  return (
    <div id="Solutions" className="mainContainer">
      <div className="mainHeader">Why we're your Perfect Match</div>
      <div className="firstRow">
        <img src={pr6} alt="" />
        <img src={pr2} alt="" />

        <img src={pr4} alt="" />
      </div>
      <div className="firstRow">

        <img src={pr5} alt="" />
        <img src={pr3} alt="" />
        <img src={pr7} alt="" />
      </div>
      <div className="firstRow">
        <img src={pr8} alt="" />
        <img src={pr9} alt="" />
        <img src={pr10} alt="" />
      </div>
      <div className="mainHeader-d">You are one step closer to Efficiency!</div>

      <div className="but-centre">
          <button onClick={openModal} className="award-button-b">Talk to our sales team</button>
      </div>
    </div>
  );
};

export default Solutions_and_services;
