import React from 'react';
import './certified_partner.css'; // Import your CSS for styling
import serv_1 from "../../img/serv_8.png";
import serv_2 from "../../img/serv_9.png";
import serv_3 from "../../img/serv_10.png";
import illustration from "../../img/serv_11.png"; // Add the left image

const CertifiedPartner = () => {
  return (
    <div className="certified-partner-wrapper-k">
      <div className="left-section-k">
        <img src={illustration} alt="Illustration" className="left-image-k" />
      </div>

      <div className="right-section-k">
        <h2 className="certified-partner-title-k">
          We are Certified Technology and Service partner of UKG
        </h2>
        <p className="certified-partner-description-k">
          This certification highlights our expertise in delivering innovative solutions that integrate seamlessly with UKG's powerful platform, helping businesses streamline their workforce operations, ensure compliance, and drive performance.
        </p>
        <p className="certified-partner-description-k">
          Our partnership allows us to offer unparalleled support and value to organizations looking to optimize their HR and workforce management strategies.
        </p>

       
      </div>
      <div className="certified-partner-logos-k">
          <div className="partner-card-k">
            <img src={serv_3} alt="UKG Certified Partner" className="partner-image-k" />
          </div>
          <div className="partner-card-k">
            <img src={serv_1} alt="UKG Technology Partner" className="partner-image-k" />
          </div>
          <div className="partner-card-k">
            <img src={serv_2} alt="UKG Innovation Partner 2023" className="partner-image-k" />
          </div>
        </div>
    </div>
  );
};

export default CertifiedPartner;
